
export class Analitika{
    constructor(options){
        var _this = this;

        this.options =
        {
            api: "http//localhost:5001",
            hermes_client: "http//localhost:5000",
            destination: 0,
            url: 'undefined'
        };

        for (const [key, value] of Object.entries(options)) {
            this.options[key] = value;
        };
        
        this.location = 0;
        this.sessionStartTime = null;
        this.initiator = '';
        this.session = {};
        this.statusData = {location: 0,
                       name: 'miza',
                       status: this.options.url,
                       data:{online: true} ,
                       health: 0,
                       memory: 0
                    };

        let hermes = import.meta.env.VITE_HERMES;
        console.log("VITE_HERMES",hermes);
        //if(hermes == 'true') {
        if(true) {
            console.log("hermes zagnan")
            setInterval(function (){

                if(_this.location == 0) {
                    _this.getLocation();
                }else{
                    _this.appReport();
                }
            }, 15000)
        }

      
    }

    setStatus(status, more, health){

        if( health == undefined ) health=0;
        this.statusData.health = health;
        this.statusData.status = status;
        this.statusData.data.more =more;
    }
    
    setStatusData(field, value){
        this.statusData.data[field] = value;
    }
    setStatusOneTimeData(value){
        this.statusData.data.more = value;
    }

    startSession(initiator){
        var _this = this;
        this.initiator = initiator;
        console.log('analitika: startSession' );
        this.sessionStartTime = Date.now();
        this.cardStartTime = [];
        this.session = {
            events: [], 
            stats: {
                card_view: {}, 
                favorit: {}, 
                card_view_time: {}
            }, 
            onboarding_compleded: 0, 
            codes_generated: {}};
        this.callHermes('session-start', {});    
    }
    report(event, data){
        if(this.location > 0) this.send(event, data)
    }

    send(event, data){
        var _this = this;
        /*
        try{
         let tmt = null;
            console.log('report_js_error faul' + tmt.err);
    }catch {
        throw 'zanalasc error';
    }
    */
        //console.log('analitika: event ' + event, data );
        //console.log('analitika performance.memory', window.performance.memory);
        var memoryInfo = null;
        if(window.performance != undefined && window.performance.memory != undefined && window.performance.memory.jsHeapSizeLimit != undefined)
            memoryInfo = {
                jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
                totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                usedJSHeapSize: window.performance.memory.usedJSHeapSize
            };
        this.session.events.push({event: event, data: {event: data, memoryInfo: memoryInfo}, time: Date.now()-this.sessionStartTime});
       if(memoryInfo != null && window.performance.memory.usedJSHeapSize/window.performance.memory.jsHeapSizeLimit > 0.5){
        this.session.events.push({event: 'memory', data: {event: data, memoryInfo: {
            jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
            totalJSHeapSize: window.performance.memory.totalJSHeapSize,
            usedJSHeapSize: window.performance.memory.usedJSHeapSize}
        }, time: Date.now()-this.sessionStartTime});
       }     
        this.generateStats(event, data);
    }



    generateStats(event, data){
        var _this = this;
        switch (event){
            case 'add_favorit': 
                _this.session.stats.favorit[data.poi] = 1;
                break;
            case 'remove_favorit': 
                delete (_this.session.stats.favorit[data.poi] );
                break;
            case 'qr_path_created': 
                if(_this.session.codes_generated[data.poi] != undefined) _this.session.codes_generated[data.poi]++;
                else _this.session.codes_generated[data.poi] = 1;
                break;
            case 'poi_click': 
                if(typeof data === 'object' &&  data != null && data.poi > 0){
                    _this.cardStartTime[data.poi] =  Date.now();
                    _this.session.stats.card_view[data.poi] = 1;
                }
                break;
            case 'hide_card': 
                if(typeof data === 'object' &&  data != null  && data.poi > 0){
                    var time =  Date.now();
                    if(_this.cardStartTime[data.poi] != undefined){
                        time = time - _this.cardStartTime[data.poi];
                        if( _this.session.stats.card_view_time[data.poi] != undefined ) _this.session.stats.card_view_time[data.poi] += time;
                        else  _this.session.stats.card_view_time[data.poi]  = time;
                    }
                }
                break;  
            case 'skip_onboarding':
                    _this.session.onboarding_compleded = data.progress;
                    break;    
            case 'complete_onboarding':
                _this.session.onboarding_compleded = 7;
                break;                  
        }
    }

    closeSession(data, callback){
        var _this = this;
        console.log('analitika: closeSession' );
        if(!(this.location > 0)){ 
            if(typeof(callback) === 'function') callback();
            console.log('session', this.session);
            return false;
        }
        this.send('session_end', data);
        
        if(data == undefined) data = {};

        let sessionEndTime =  Date.now();
        this.session.duration = sessionEndTime - this.sessionStartTime;
        this.session.location = this.location;
        this.session.destination =this.options.destination;
        this.session.data = data;
        this.session.data.sessionStartTime = this.sessionStartTime;
        this.session.data.sessionEndTime = sessionEndTime;
        //this.session.data.userSession = userSession;
        console.log('session', this.session);
        

        if(this.location > 0) this.callApi('LocationRequests', 'saveSessionAnalytics', this.session , function(response){
            console.log(response);
            //alert('zdej mamo');
            //_this.startSession('after_close_session');
            //if(typeof(callback) === 'function') callback(response);
        });
        else{
            console.log('nismo na lokaciji');
        }
        this.callHermes('session-close', {});
    }



    start_interact(poiProperties){
        //console.log('start_interact'+(poiProperties.presentation  != undefined)+(poiProperties.presentation.aditional != undefined )+Array.isArray(poiProperties.presentation.aditional.screens) +poiProperties.presentation.aditional.screens.length)
        if(this.location > 0 
                && poiProperties.presentation  != undefined 
                && poiProperties.presentation.aditional != undefined 
                && Array.isArray(poiProperties.presentation.aditional.screens) 
                && poiProperties.presentation.aditional.screens.length > 0 ) 
            this.send_app_request({location: this.location, action: 'video_play', screens: poiProperties.presentation.aditional.screens});
    }
    stop_interact(){
        if(this.location > 0) this.send_app_request({location:  this.location, action: 'video_unplay'});
    }


    //
    send_app_request(data){
        var _this = this;
        var xmlhttp;
        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
    
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                console.log(xmlhttp.responseText);
            }
        }
        xmlhttp.open('POST',this.options.hermes_client+"/send_app_request", true);
        //xmlhttp.setRequestHeader("Content-Type", "application/json; charset = UTF-8");
        xmlhttp.send(JSON.stringify(data));
    }

    printPDF(base64Data){
        var _this = this;
        if(this.location == 0) return;
        var xmlhttp;
        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
    
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                console.log(xmlhttp.responseText);
            }
        }
        xmlhttp.open('POST',this.options.hermes_client+"/print-pdf", true);
        //xmlhttp.setRequestHeader("Content-Type", "application/json; charset = UTF-8");
        xmlhttp.send(JSON.stringify({toprint: base64Data}));
    }


    callApi(iface, method, parameters, callback){
        const jsonRequest = this.formatRequest(iface, method, parameters);

        console.log('calling api'); 
        var xmlhttp;
        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
    
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 )
            {
                if(xmlhttp.status==200)
                {
                    var response = {error:{type: 'server', msg: 'Could not parse response'}}
                    try{
                        response = JSON.parse(xmlhttp.responseText);
                    }catch{
    
                    }
                    if(typeof(callback) === 'function') callback(response);
                }
                else{
                    if(typeof(callback) === 'function') callback({error:{type: 'server', msg: 'error at server side, status:'+xmlhttp.status}});
                }

            }
        }
        xmlhttp.open('POST',this.options.api, true);
        /*xmlhttp.setRequestHeader("Content-Type", "application/json; charset = UTF-8");
        xmlhttp.setRequestHeader('Access-Control-Allow-Origin', '*');*/
        xmlhttp.send(jsonRequest);
    }
    getLocation(){
        var _this = this;
        var xmlhttp;
        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
    
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                _this.location = parseInt(xmlhttp.responseText);
                _this.statusData.location = _this.location;
            }
        }
        xmlhttp.open('GET',this.options.hermes_client+"/get-location", true);
        xmlhttp.send();
    }
    getMessages(){
        var _this = this;
        var xmlhttp;

        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
    
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                console.log('got  messages: ' +xmlhttp.responseText);
                switch(xmlhttp.responseText){
                    case 'miza_reload': window.location.reload(); break;
                }
            }
        }
        xmlhttp.open('POST',this.options.hermes_client+"/get-messages", true);
        xmlhttp.send();
    }

    appReport(callback){
        var _this = this;
        var xmlhttp;
        //var out = {};

        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
        
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                console.log('got  server requests: ' +xmlhttp.responseText);
                try{
                    let data = JSON.parse(xmlhttp.responseText);
                    switch(data.action){
                        case 'reload': 
                        case 'restart':
                            //console.log('got  server requests action:' + data.action);
                            window.location.reload();
                            break;
                    }
                    if(data.localmesages != undefined && Array.isArray(data.localmesages))
                        data.localmesages.forEach((msg) => {
                            switch(msg){
                                case 'app_updated':
                                    break;
                                case 'data_updated':
                                        break;      
                                case 'reset-pedestrians':
                                    localStorage.removeItem('pedestrian')
                                    break;                                            
                            }
                        });
                   
                }catch{

                }
                if(typeof(callback) === 'function'){
                    callback();
                }
                
            }
        }


        var memoryInfo = null;
        if(window.performance != undefined && window.performance.memory != undefined && window.performance.memory.jsHeapSizeLimit != undefined)
            memoryInfo = {
                jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
                totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                usedJSHeapSize: window.performance.memory.usedJSHeapSize
            };
       if(memoryInfo != null){
         this.setStatusData('memoryInfo', memoryInfo);
       }    




        xmlhttp.open('POST',this.options.hermes_client+"/app-report", true);
        //xmlhttp.setRequestHeader("Content-Type", "application/json");
        xmlhttp.send(JSON.stringify(this.statusData));
        this.statusData.data.more = null;
        //return out;
    }


    callHermes(str, data){
        if(this.location == 0) return;
        var _this = this;
        var xmlhttp;

        if (window.XMLHttpRequest)
            xmlhttp=new XMLHttpRequest();
        else
            xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    
        
        xmlhttp.onreadystatechange=function()
        {
            if (xmlhttp.readyState==4 &&  xmlhttp.status==200)
            {
                console.log('got response from hermes: ' +xmlhttp.responseText);
                
            }
        }

        xmlhttp.open('POST',this.options.hermes_client+"/"+str, true);
        //xmlhttp.setRequestHeader("Content-Type", "application/json");
        xmlhttp.send(JSON.stringify(data));

    }
    /*
    formatMemory(bytes){
        let str = '';
        let remaining = bytes;
        if(remaining > 1000000){
            string += Math.round(remaining/1000000) + "M ";
            remaining = remaining % 1000000;
        }
        if(remaining > 1000){
            string += Math.round(remaining/1000) + "k";
            remaining = remaining % 1000;          
        }
        return str;
    }
    */

    report_js_error(message, source, lineno, colno, error, destination, callback){
        if(this.location < 1)  return false;
        let errstring = '';
        if(error != null && error.stack != undefined) errstring = error.stack;
        let parameters = {location: this.location, destination: destination, app: 'miza2', message: message, source:source, 
                          lineno: lineno, colno: colno, error: errstring};
        //console.log('report_js_error', parameters);
        this.callApi('DeviceManager', 'report_js_error', parameters, function(response){
            console.log('report_js_error response ', response);
            if(typeof(callback) === 'function'){
                callback();
            }
        });
      }

    formatRequest(iface,method, parameters){
        var obj = new Object();
        var request = new Object();
        request.interface = iface;
        request.method = method;
        request.parameters = parameters;
        obj.request = request;
        return JSON.stringify(obj);
    
    }

    getMemory(){
        var memoryInfo = null;
        if(window.performance != undefined && window.performance.memory != undefined && window.performance.memory.jsHeapSizeLimit != undefined)
            memoryInfo = {
                jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
                totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                usedJSHeapSize: window.performance.memory.usedJSHeapSize
            };
       return memoryInfo;
    }


}