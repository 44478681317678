<template>
     <Transition name="iframe-content">
        <div>
    <iframe sandbox="allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts" :src='url'></iframe>
    <Header :setupHeader="setupDataHeader"></Header>
</div>
</Transition>
</template>
<script setup>
import { computed,onMounted,onUnmounted,onBeforeMount,ref } from 'vue';
import Header from '@/components/Header.vue'
import { useMapStoreData } from '@/stores/index'
import { useRoute } from 'vue-router';
const id = computed(() => route.params.id);
const route = useRoute();
const url = computed(() => {
    return useMapStoreData().routes[id.value].url;
});
const showIframe = ref(false);
const moduleData = useMapStoreData().routes[id.value];
const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: false,
  backArrow: false,
  backArrowMap: true,
  shortHeader: false,
  hideLanguage: true,
}
onMounted(() => { 
    setTimeout(() => {
    showIframe.value = true
  }, 50);
    useMapStoreData().hideMap = true;
});
onBeforeMount(() => {
    showIframe.value = false;
    useMapStoreData().hideMap = true;
});
onUnmounted(() => {
    useMapStoreData().hideMap = true;
    console.log('IFrame component is being unmounted')
});
</script>
<style scoped>
@import '../assets/style/mixins.scss';

.iframe-content-enter-active,
.iframe-content-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.iframe-content-enter-from,
.iframe-content-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.container-iframe {
    z-index: 1;
}
iframe {
    margin-top: 6.25vw;
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: calc(100vh - 6.25vw);
    border: none;
}

@media only screen and (orientation: portrait) { 

iframe {
    margin-top:0;
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: calc(100vh - 6vh);
    border: none;
}
}
</style>