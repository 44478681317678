<template>
  <!--<div v-show="mapStore.showMap || pathRoute" class="map-container">-->
  <div v-show="!useMapStoreData().hideMap"  class="map-container">
    <Header @closeMap="closePoiPresentation" :setupHeader="setupDataHeader"></Header>

    <div id="map" style="width: 100vw; height: 100vh; right:0; top:0;"></div>
    <LjBranding v-if="useMapStoreData().destinationId == '23'" v-show="mapStore.showMap"></LjBranding>
    <ptujBranding v-if="useMapStoreData().destinationId == '17'" v-show="mapStore.showMap"></ptujBranding>
    <Transition name="fade">
    <div v-if="storeMap.mapLoader" class="loading">
      <div class="container">
        <CircularProgress :progress="loaderLength" color="#2E3D45"></CircularProgress>
      </div>
   </div>
  </Transition>
  </div>
  
</template>

<script setup>
import { computed, ref, onMounted,watch,defineAsyncComponent,createApp,onBeforeMount } from "vue";
import { useRoute } from 'vue-router';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useMapStoreData, useMarkersStore, useSetMap, useCategoriesStore,useScreensaverStore,useSearchData,useEventStore  } from '@/stores/index'
import Header from '@/components/Header.vue'
import 'swiper/css';
import newClusterMarker from '@/assets/Multi_Marker3x.png';
import * as turf from '@turf/turf'
import * as togeojson from "togeojson";
import currentLocationMapIcon from '@/assets/current-location-map-icon.png'
import LjBranding from "@/components/branding/LjBranding.vue";
import ptujBranding from "@/components/branding/PtujBranding.vue";
import offlineStyle from '@/assets/offlineStyle.json'
import {addOfflineMapSourceLayer,removeOfflineMapSourceLayer,isServerOnline} from '@/utils/offlineHandling.js'
import CircularProgress from '@/components/CircularProgress.vue'

const route = useRoute();
const pathRoute = computed(() => route.path !== '/scenarios/');
const mapStore = useMapStoreData();
const screenSaverStore = useScreensaverStore();
const storeMap = useSetMap();
const useMarkers = useCategoriesStore();
const markersStore = useMarkersStore();
const map = ref(null);
const clusters = ref({
        type: 'FeatureCollection',
        features: [] 
      });
const imagesLoaded = ref(0);
const mapLoader = ref(true);
let markerWithPath = false;
let popupDiv = null;
let poiPopup = null;
const PopupComponent = defineAsyncComponent(() => import('@/components/PoiCard.vue'));
const windowMaxDim = Math.max(window.innerWidth,window.innerHeight )

var debugToolActive = false;
var imageMarkerLayerReady = false;
var closeMarkerLayerReady = false;
var closeEventMarkerLayerReady = false;

var mapLoaded = false; // map loaded ready for adding images and layers
var mapReady = ref(false); // map layers and images for minimal functionality loaded

var debugLayers = [];
var activeDebugLayer = {clusters: '', markers: ''};
var mapLoadProgress = ref(0);
const allEventLength = ref(0);

//const windowWidth = window.screen.availWidth; //device screen width not browser window width

const setupDataHeader = {
  centerIcon: true,
  orderFilter: false,
  search: true,
  backArrow: false,
  backArrowMap: true
}

const mapData = ref();

var loaderLength = computed(() => {
  const obligatoryMarkersCount = 8;
  let obligatoryMarkersLoaded = markersStore.countCategoriesMarkers + markersStore.markerFavoritesCount + markersStore.closeMarkersCount + markersStore.closeMarkersWithEventCount;
  if(obligatoryMarkersLoaded > obligatoryMarkersCount) obligatoryMarkersLoaded = obligatoryMarkersCount;
  let perc = (80 * obligatoryMarkersLoaded / obligatoryMarkersCount) + (0.2 * mapLoadProgress.value);
  return perc;
});

mapStore.options.minClusterSize = 0.22;
mapStore.options.maxClusterSize = 0.92;
mapStore.options.minClusterSize = 0.161;
mapStore.options.maxClusterSize = 0.59;
mapStore.options.minClusterSize = 0.133;
mapStore.options.maxClusterSize = 0.315;
mapStore.options.minMarkerSize = 0.175;
mapStore.options.maxMarkerSize = 0.5;
mapStore.options.markerZoomRatio = 3;
mapStore.options.showCategoryIconOnZoomOut = true;
mapStore.options.clusterMaxZoom = 16;

var showingImageMarkers = false;

onMounted(async () =>{
  useMapStoreData().hideMap = false;
  console.log('spura MApview Mountan');
  await isServerOnline('https://miza.simpl.guide/')
  mapData.value = mapStore;
  let clusterstemp = mapData.value.clusters;
  clusterstemp.features.forEach(feature => {
    feature.properties.hasOpaqueMarker = true;
  });

  document.querySelector('body').style.overflowY = 'hidden';

  Object.keys(useMapStoreData().markers).forEach(function(key, idx,array) {
      if(useMapStoreData().markers[key].properties.type === 'event') {
        let startDate = new Date(useMapStoreData().markers[key].properties.start);
        let endDate = new Date(useMapStoreData().markers[key].properties.end);

    // Remove the time part of the dates
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    let currentDateObject = new Date();
    currentDateObject.setHours(0, 0, 0, 0); 
    useEventStore().allEventsId.push(key);
    // Check if event is today or in the past but not ended yet or starts within the next month
    if (startDate.getTime() === currentDateObject.getTime() ||
        (startDate.getTime() < currentDateObject.getTime() && endDate.getTime() >= currentDateObject.getTime()) ||
        (startDate.getTime() > currentDateObject.getTime() && startDate.getTime() < currentDateObject.getTime() + 30 * 24 * 60 * 60 * 1000)) {
        const parentId = useMapStoreData().markers[key].properties.parent;
        useEventStore().validEventsIds.push(key);
        if(parentId == '0') {
          useEventStore().validEventsOnMap.push(key);
        }else {
          let parentCoords = useMapStoreData().markers[parentId].geometry.coordinates;
          let eventCoords = useMapStoreData().markers[key].geometry.coordinates;
          if(parentCoords[0] != eventCoords[0] && parentCoords[1] != eventCoords[1]) {
            useEventStore().validEventsOnMap.push(key);
          }
        }
    }else {
      useEventStore().invalidEventsIds.push(key);
    }
  }

    if(useMapStoreData().markers[key].properties.hasOwnProperty('events')) {
        let cat = useMapStoreData().markers[key].properties.categories[0];
        cat = cat + '-' + useMapStoreData().markers[key].properties.categories[1];
        useEventStore().markersWithEvents.push({key:key,cat:cat});
        useEventStore().eventsWithMarkers.push(...useMapStoreData().markers[key].properties.events);
      }
    });
    //debugger;
    //count 
    let tempmarkersWithEvents = useEventStore().markersWithEvents;

    useEventStore().markersWithEvents = tempmarkersWithEvents.filter(marker => {
      return useMapStoreData().markers[marker.key].properties.events.some(event => {
      // if one of the events is valid then the marker is valid and push it to markersWithEvents
      return !useEventStore().invalidEventsIds.includes(event);
    });
});
    clusters.value.features = clusterstemp.features.filter(cluster =>{
      return !useEventStore().invalidEventsIds.includes(cluster.properties.id)
    });
    clusters.value.features = clusterstemp.features.filter(cluster =>{
      return !useEventStore().invalidEventsIds.includes(cluster.properties.id)
    });
    // count number of type event in clusters.value.features
    useEventStore().eventNumberFilter =  clusters.value.features.filter((itemmarker) => itemmarker.properties.type == 'event').length;
    allEventLength.value = useEventStore().eventNumberFilter;
    createPopupApp();
  //initMap();
});

function initMap() {
  console.log('MApview initMap start');
  mapboxgl.accessToken = 'pk.eyJ1IjoiYXI5aXQiLCJhIjoiY2tzMDIzdDZoMWZ2bDJxczduMDJrcXVnYyJ9.cRFgHrdHVNT2-euD0Lgn0Q';
  let lng = parseFloat(mapStore.options['lng']);
  let lat = parseFloat(mapStore.options['lat']);
  //if zoom is 14 
  let radiusRatio = 50 / mapStore.options.zoom;
  const bounds = [
      [lng-radiusRatio,lat-radiusRatio],
      [lng+radiusRatio,lat+radiusRatio]
    ];
  
    if (!useMapStoreData().isOnline) {
      useMapStoreData().hideMapFromHome = false;	
      return;
    }else {
        map.value = new mapboxgl.Map({
        container: 'map', 
        style: mapStore.options.options.style, 
        center: [lng, lat],
        zoom: mapStore.options.zoom,
        minZoom: 7,
        maxBounds:bounds});
      
        useMapStoreData().hideMapFromHome = true;	       
    }
  



  //map.value.setStyle(offlineStyle); 


  


  storeMap.setMapBox(map.value);
  // disable map rotation using right click + drag
  map.value.dragRotate.disable();
  // disable map rotation using touch rotation gesture
  map.value.touchZoomRotate.disableRotation();

  map.value.on('load', () => {
    console.log('MApview map on load');
    mapLoaded = true;
    checkMarkersReady();
    /*
    loadMapMarkersAndLayers();
    markerClickMapListener();
    clusterClickMapListener();
    */
    map.value.on('error', function(e) {
    if(e.sourceId == "composite") {
      addOfflineMapSourceLayer();
  }
});
    //mapRadiusFilter();
    map.value.on('sourcedata', handleSourceData);
    map.value.on('moveend', function (event) {
      screenSaverStore.resetScreensaverTimer();
    });

    const mapCanvas = document.querySelector('#map .mapboxgl-canvas');
    const gl = mapCanvas.getContext('webgl');
    mapCanvas.addEventListener('webglcontextlost', (event) => {
      window.location.reload();
    });
  });

  map.value.on('zoom', () => {
    let zoom = map.value.getZoom();
    if(mapStore.options.showCategoryIconOnZoomOut){
      
      if(showingImageMarkers &&   zoom < mapStore.options.clusterMaxZoom + 1){
        map.value.setLayoutProperty('image-markers', 'visibility', 'none');
        map.value.setLayoutProperty('markers', 'visibility', 'visible');
        showingImageMarkers = false;
      }else if (imageMarkerLayerReady && !showingImageMarkers &&   zoom >= mapStore.options.clusterMaxZoom + 1){
        console.log('pokazemo slike');
        map.value.setLayoutProperty('markers', 'visibility', 'none');
        map.value.setLayoutProperty('image-markers', 'visibility', 'visible');
        showingImageMarkers = true;
      }
    }
    if(zoom < mapStore.options.clusterMaxZoom + 1){
      let textprops = calculateClusterTextProps( mapStore.options.minClusterSize, mapStore.options.maxClusterSize, zoom)
      map.value.setLayoutProperty('clusters', 'text-size', textprops.size);
    }
    
  });

}

const checkMarkersReady =  () => {
  if(markersStore.functionalMarkersLoaded && mapLoaded){
    loadMapMarkersAndLayers();

    //mapReady = true;
    //checkImageMarkersReady();
    markersStore.createImageMarkers();
  }
};


const checkImageMarkersReady = () => {
  //zdaj bl k ne nepotrebno, ker se dela to sekvencno
  if(markersStore.markersWithImageCreated && mapReady.value){
    loadMapImageMarkersAndLayers();
    
    //imageMarkerLayerReady = true;
    let zoom = map.value.getZoom();
    if ( zoom >= mapStore.options.clusterMaxZoom + 1){
        map.value.setLayoutProperty('markers', 'visibility', 'none');
        map.value.setLayoutProperty('image-markers', 'visibility', 'visible');
    }
  }
}



const loadImageAsync = (map, image) => {
  return new Promise((resolve, reject) => {
    map.loadImage(image, (error, loadedImage) => {
      if (error) reject(error);
      else resolve(loadedImage);
    });
  });
};

/**********************************/
/*EVERYTHING LOADING TO MAP*/
/**********************************/

const loadMapImageMarkersAndLayers = async () => {
  let imageMarkerPart = [];
  try {

    useEventStore().validEventsOnMap.forEach(function(key) {
        imageMarkerPart.push(['==', ['get', 'id'], key], 'event-marker');
    });

    useEventStore().markersWithEvents.forEach(function(key) {
      imageMarkerPart.push(['==', ['get', 'id'], key.key], 'marker-' + key.cat + '-event');
    });

    for (let [key, value] of markersStore.markerThumbnails) {
      let image = await loadImageAsync(map.value, value);
      imagesLoaded.value = imagesLoaded.value + 1;
      imageMarkerPart.push(['==', ['get', 'id'], key], `image-thumb-marker-${key}`);
      map.value.addImage('image-thumb-marker-' + key, image);
    }

    console.log('spura image marker jo dodani');
    addImageMarkerLayer(imageMarkerPart,mapStore.options.minMarkerSize,mapStore.options.maxMarkerSize, '');
    console.log('spura image marker  layer dodan ');

  } catch (error) {
    console.error('An error occurred:', error);
  }
};

const loadMapMarkersAndLayers = async () => {
  let expressionMarkerPart = [];
  try {
    console.log('MApview loadMapMarkersAndLayers start');
    

    //if(mapStore.options.showCategoryIconOnZoomOut){ // slike samo na zoom
      //console.log("useEventStore().eventsIds",useEventStore().eventsIds);
    useEventStore().validEventsOnMap.forEach(function(key) {
      expressionMarkerPart.push(['==', ['get', 'id'], key], 'event-marker');
    });
    
    useEventStore().markersWithEvents.forEach(function(key) {
      expressionMarkerPart.push(['==', ['get', 'id'], key.key], 'marker-' + key.cat + '-event');
    });

    for (let [key, value] of markersStore.categoryMarkersEventLook) {
      let image = await loadImageAsync(map.value, value);
      map.value.addImage('marker-' + key, image);
    }

    for (let [key, value] of markersStore.categoryMarkersLook) {
      let image = await loadImageAsync(map.value, value);
      let categories = key.split('-');
      imagesLoaded.value = imagesLoaded.value + 1;
      expressionMarkerPart.push([
        'all',
        ['==', ['at', 0, ['get', 'categories']], categories[0]],
        ['==', ['at', 1, ['get', 'categories']], categories[1]],
      ], `image-marker-${key}`);
      map.value.addImage('image-marker-' + key, image);
    }

    mapLoadProgress.value = 40;



    for (let [key, value] of markersStore.markerFavorites) {
      let image = await loadImageAsync(map.value, value);
      map.value.addImage(key + '-image', image);
    }
    mapLoadProgress.value = 50;


    for (let [key, value] of markersStore.closeMarkers) {
      let image = await loadImageAsync(map.value, value);
      map.value.addImage(key + '-image', image);
    }
    mapLoadProgress.value = 55;
    for (let [key, value] of markersStore.closeMarkersWithEvent) {
      let image = await loadImageAsync(map.value, value);
      console.log('close-' + key + '-image');
      map.value.addImage('close-' + key + '-image', image);
    }
    mapLoadProgress.value = 60;
    let imageEvent = await loadImageAsync(map.value, useMarkersStore().eventMarkers.get('eventMarker'));
    let closeEventMarker = await loadImageAsync(map.value, useMarkersStore().eventMarkersClose.get('closeEventMarker'));
    let clusterImage = await loadImageAsync(map.value, newClusterMarker);
    let currentLocation = await loadImageAsync(map.value, currentLocationMapIcon);
    map.value.addImage('event-marker', imageEvent);
    map.value.addImage('close-event-image', closeEventMarker);
    map.value.addImage('cluster-marker', clusterImage);
    map.value.addImage('current-location-marker', currentLocation);
    setupClusterSource();
    //console.log('kurac setup cluster source');
    mapLoadProgress.value = 70;

    //console.log('%c All markers have been loaded and added!', 'color: green');
    //markersStore.allMarkersLoaded = true;
  
    
    console.log('spura ovvezni markerji dodani na mapo');

    addClusterLayer(mapStore.options.minClusterSize, mapStore.options.maxClusterSize,'');
    mapLoadProgress.value = 80;

    addMarkerLayer(expressionMarkerPart,mapStore.options.minMarkerSize,mapStore.options.maxMarkerSize, '');
    
    mapLoadProgress.value = 90;
    addFavoritesLayer();
    addCloseMarkerLayer();
    addCloseEventMarkerLayer();
    mapLoadProgress.value = 100;
    addCurrentLocationLayer();
    
    console.log('spura ovvezni layerji dodani na mapo');
    //mapReady.value = true; 
    
    markerClickMapListener();
    clusterClickMapListener();
    if(useCategoriesStore().defaultActiveFiltersParentsId.length > 0) {
      clearFilters();
    }
    
    //mapLoader.value = false;
    storeMap.mapLoader = false;
    useMarkersStore().mapSeen = true;


    initSizeTool();
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

const clearFilters = () => { // drejc 
  useSetMap().returnFilteredMarkersMap();
  let source = map.value.getSource('clustering');
  useSetMap().moveToFilterBounds(source);
  /*
    let bounds = markers.reduce(function(bounds, marker) {
      return bounds.extend(marker.geometry.coordinates);
  }, new mapboxgl.LngLatBounds(markers[0].geometry.coordinates, markers[0].geometry.coordinates));

  
  map.value.fitBounds(bounds, {
    padding: 250,  
    maxZoom: 18  
  });*/
  /*try{
  updateClusterOpacityBasedOnActiveFilters();
    if (useMarkers.activeFilters.length == 0) {
      map.value.setPaintProperty('markers', 'icon-opacity', 1);
      try{
        if(imageMarkerLayerReady) {
          map.value.setPaintProperty('image-markers', 'icon-opacity', 1);
        }
      }catch{};
      map.value.setPaintProperty('clusters', 'icon-opacity', 1);
    } else {
      const filterExpression = generateActiveMarkerFilterExpression(useMarkers.activeFilters);
        map.value.setPaintProperty('markers', 'icon-opacity', filterExpression);
        try{
          if(imageMarkerLayerReady) {
            map.value.setPaintProperty('image-markers', 'icon-opacity', filterExpression);
          }
        }catch{};
        const clusterOpacityExpression = [
            'case',
            ['==', ['get', 'hasOpaqueMarker'], true],
            1,
            0.3
        ];
        map.value.setPaintProperty('clusters', 'icon-opacity', clusterOpacityExpression);
    }
  }catch{
    console.log('clustering not ready yet');
  }*/
}

const createPopupApp = () => {
  popupDiv = document.createElement('div');
  const currentPopupApp = createApp(PopupComponent);
  currentPopupApp.mount(popupDiv);
}




/****************************/
/*DEBUG && TOOLS*/
/****************************/
const initSizeTool = () => {
  let delta = {markers: 0.005, clusters: 0.005};
  window.addEventListener("keydown", (e) => {
    const key = e.key;

    if (e.ctrlKey && e.altKey && key === 'd') {
      document.getElementById('debug').innerHTML = 'DEV TOOLS ON';
      console.log('dev tools are on');
      debugToolActive = true;
    }

    if(!debugToolActive) return;

    let type;
    let up;
    let def;
    
    switch(key){
      case 'f': type = 'image-markers', up = 1; def = 'maxMarkerSize' ; break;
      case 'v': type = 'image-markers', up = -1; def = 'maxMarkerSize'; break;
      case 'g': type = 'clusters', up = 1; def = 'maxClusterSize' ; break;
      case 'b': type = 'clusters', up = -1; def = 'maxClusterSize'; break;
      case 'y': 
      case 'Escape':
        debugToolActive = false;
        console.log('dev tool are off');
        document.getElementById('debug').innerHTML= ''; 
        activeDebugLayer = {clusters: '', markers: ''};
        let zextprops = calculateClusterTextProps( mapStore.options.minClusterSize, mapStore.options.maxClusterSize) ;
        map.value.setLayoutProperty('clusters', 'text-size', zextprops.size);
        map.value.setLayoutProperty('clusters', 'icon-size', scaleZoomMatrix(mapStore.options.markerZoomRatio, mapStore.options.minClusterSize , mapStore.options.maxClusterSize));
        map.value.setLayoutProperty('image-markers', 'icon-size', scaleZoomMatrix(mapStore.options.markerZoomRatio, mapStore.options.minMarkerSize  ,mapStore.options.maxMarkerSize, true));
        return; 
      default: return;
    }
    
    let curSize = activeDebugLayer[type];
    if(curSize == '' ) curSize = mapStore.options[def]
    let newSize = curSize + up * delta[type];
    console.log('curSize: ' +curSize +'   newSize: '+newSize) 
    /*
    displayDebugLayer(type, 'max', newSize);  
    */
    activeDebugLayer[type] = newSize;
    document.getElementById('debug').innerHTML = "maxClusterSize: "+ activeDebugLayer.clusters 
                                                + "<br/>maxMarkerSize: "+activeDebugLayer.markers 
                                                + "<br/>zoom: "+map.value.getZoom();

    if(type=='image-markers') {
      newSize = scaleZoomMatrix(mapStore.options.markerZoomRatio, mapStore.options.minMarkerSize  ,newSize, true);
    }else{
      let textprops = calculateClusterTextProps( mapStore.options.minClusterSize, newSize) ;
      newSize = scaleZoomMatrix(mapStore.options.markerZoomRatio, mapStore.options.minClusterSize  ,newSize);
      map.value.setLayoutProperty('clusters', 'text-size', textprops.size);
    }
    map.value.setLayoutProperty(type, 'icon-size', newSize);

  });
};

// not needed ATM
const displayDebugLayer = (type, direction, size) => {
  console.log('diplayamo '+ type + "  velikosti:" + size)
  let newid = type+size;
  if(activeDebugLayer[type] == size ) return;

  map.value.setLayoutProperty(type+activeDebugLayer[type], 'visibility', 'none');

  if(debugLayers.indexOf(newid) > -1){
    map.value.setLayoutProperty(newid, 'visibility', 'visible');
  }
  else{
    switch(type){
      case 'clusters':
        addClusterLayer(mapStore.options.minClusterSize, size,size);
        break;
      case 'markers':
        addMarkerLayer(mapStore.options.minMarkerSize, size,size);
        break;  
    }
    debugLayers.push(newid);
    activeDebugLayer[type] = size;
    console.log('iconsize '+ type + ": "+size)
  }
}

/****************************/
/*EVENTS*/
/****************************/

const clusterClickMapListener = () => {
  map.value.on('click', 'clusters', function (e) {

    var features = map.value.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });

    if (!features.length) return;

    var clusterId = features[0].properties.cluster_id;
    map.value.getSource('clustering').getClusterLeaves(clusterId, Infinity, 0, (err, markers) => {
      if (err) {
        return console.error(err);
      }
      useSetMap().moveToFilterBounds(markers);
      // Calculate the bounding box for the cluster's markers
      let bounds = markers.reduce(function(bounds, marker) {
          return bounds.extend(marker.geometry.coordinates);
      }, new mapboxgl.LngLatBounds(markers[0].geometry.coordinates, markers[0].geometry.coordinates));

      // Adjust the map view to fit the bounding box
      map.value.fitBounds(bounds, {
        padding: 250,  // padding around the box for better view
        maxZoom: 18  // this can be set to ensure the map doesn't zoom in too far
      });

    });
  });
}
const imageMarkerClickMapListener = () => {

  if(mapStore.options.showCategoryIconOnZoomOut)
    map.value.on('click', 'image-markers', function (e) {
      const { id: clickedFeatureId } = e.features[0].properties;
      if (useMapStoreData().activePoi == clickedFeatureId) {
        closePoiPresentation(); 
        return;
      }
      useMapStoreData().activePoi = clickedFeatureId;
  });
}

const markerClickMapListener = () => {
  map.value.on('click', 'markers', function (e) {
    const { id: clickedFeatureId } = e.features[0].properties;
      if (useMapStoreData().activePoi == clickedFeatureId) {
        closePoiPresentation(); 
        return;
      }
      useMapStoreData().activePoi = clickedFeatureId;
  });
}
//close markerji nimajo propertisov?, zato ne bo delalo
/*
const closermarkerClickMapListener = () => {
  map.value.on('click', 'closeMarkerLayer', function (e) {
    const { id: clickedFeatureId } = e.features[0].properties;
      if (useMapStoreData().activePoi == clickedFeatureId) {
        closePoiPresentation(); 
        return;
      }
      useMapStoreData().activePoi = clickedFeatureId;
  });
}
*/

/***********************/
/*VARIOUS FUNCTIONS*/
/***********************/

const openMarker = (id) => {
  const clickedFeatureId = id;
  const markerData = mapStore.markers[id];
  const clickedCoordinates = markerData.geometry.coordinates;
  const { properties} = markerData;
  let categoryColor = '';
  let hasEvent = false;
  if(!useMapStoreData().isOnline) {
    return;
  } 
  if(properties.type =='event') {
    categoryColor = 'event';
  }else {
    categoryColor = properties.categories[0]
  }
  
  if(markerData.properties.events != undefined) {
    if(markerData.properties.events.length > 0) {
      markerData.properties.events.forEach(function(event) {
        if(!useEventStore().invalidEventsIds.includes(event)) {
          categoryColor = 'with-event-' + categoryColor;
          hasEvent = true;
        }
      });
    }
  }

  if(useEventStore().eventsWithMarkers.includes(id)) {
    if(!useEventStore().invalidEventsIds.includes(id)) {
      categoryColor = 'marker-with-opened-event';
      hasEvent = true;
    }
  }

  if(markerWithPath) {
    useSetMap().deletePath('markerPath');
    markerWithPath = false;
  }
  const object = {
    id: clickedFeatureId,
    type: "Feature",
    properties: {
        category:"close-" + categoryColor
    },
    geometry: {
        type: "Point",
        coordinates: [...clickedCoordinates], 
    },
  };
  if(hasEvent) {
    map.value.getSource('closeSourceMarker').setData({
      type: 'FeatureCollection',
      features: []  
    });
    map.value.getSource('closeEventSourceMarker').setData(object);
  }else {
    map.value.getSource('closeEventSourceMarker').setData({
      type: 'FeatureCollection',
      features: []  
    });
    map.value.getSource('closeSourceMarker').setData(object);
  }
  //map.value.getSource('closeSourceMarker').setData(object);
  //map.value.getSource('closeEventSourceMarker').setData(object);

  let zoom = 16;
  if (map.value.getZoom() > 16) {
    zoom = map.value.getZoom();
  }

  map.value.flyTo({
    center: [clickedCoordinates[0], clickedCoordinates[1]],
    zoom: zoom
  });
 
    showPopup(clickedFeatureId);

  if (properties.type !== 'path') return;
    addPath(mapStore.markers[clickedFeatureId]);
}

function closePoiPresentation() {
  useMapStoreData().activePoi  = null;
  if(markerWithPath) {
    useSetMap().deletePath('markerPath');
    markerWithPath = false;
  }
  if(poiPopup != null) { 
    poiPopup.remove();
    poiPopup = null;
  }
  if(closeMarkerLayerReady && closeEventMarkerLayerReady) {
    map.value.getSource('closeSourceMarker').setData({
      type: 'FeatureCollection',
      features: []  
    });
    map.value.getSource('closeEventSourceMarker').setData({
      type: 'FeatureCollection',
      features: []  
    });
  }
  
}

const addPath = async (marker) => {
    const { path_geometry, files } = marker.properties;
    let color = useCategoriesStore().returnStartStopColor(marker.properties.categories[0]);
    let data;

    // Check if the marker has path geometry or gpx path
    if (path_geometry) {
        /*data = path_geometry;
        console.log("marker.id",marker);*/
        let path = {
                  type: 'Feature',
                  properties: {},
                  geometry: path_geometry,
              }
        useSetMap().createPath('path-' + marker.properties.id,path,'markerPath');
    } else if (files?.gpx_path?.file) {
        const file = marker.properties.files.gpx_path.file;
        const path = `${mapStore.publicUrl}repo/${mapStore.destinationId}/${file.hash}.${file.ext}`;

        // Fetch the gpx file and convert it to geojson
        const response = await fetch(path);
        const str = await response.text();
        const doc = new DOMParser().parseFromString(str, "text/xml");
        const fulldata = togeojson.gpx(doc);
        useSetMap().createPath('path-' + marker.properties.id,fulldata,'markerPath');
        //data = fulldata.features[0].geometry;
    }
    markerWithPath = true;
    if (!data) return;
  
    map.value.addSource('pathSource', {
        'type': 'geojson',
        'data': {
            "type": "Feature",
            "properties": {},
            "geometry": data
        }
    });

    map.value.addLayer({
        'id': 'pathLayer',
        'type': 'line',
        'source': 'pathSource',
        'layout': {},
        'paint': {
            'line-color': color[0] || '#000000',
            'line-width': 5
        }
    });
}


/*const updateClusterOpacityBasedOnActiveFilters = () => {
    // Modify the underlying GeoJSON data's hasOpaqueMarker property based on your active filters
    let markers = clusters.value;
    
    if(useSearchData().searchFilteredItems.length > 0) {
      markers = {
        type: 'FeatureCollection',
        features: useSearchData().searchFilteredItems 
      }
    }
    
    for (let feature of markers.features) {
        // Check if this feature has any category that matches the active filters
        const hasMatchingCategory = feature.properties.categories.some(category => 
            useMarkers.activeFilters.includes(category)
        );
        
        if (hasMatchingCategory) {
            feature.properties.hasOpaqueMarker = true;
        } else {
            feature.properties.hasOpaqueMarker = false;
        }
    }

    // Make a deep copy of clusters.value to ensure changes are detected
    const updatedClusters = JSON.parse(JSON.stringify(markers));
    // Update the data source to trigger a re-clustering
    map.value.getSource('clustering').setData(updatedClusters);
};*/

function showPopup(clickedFeatureId) {
  poiPopup = new mapboxgl.Popup(
{closeOnClick: false,closeButton:false,anchor: 'left' ,offset: [60, 0]})
    .setLngLat(mapStore.markers[clickedFeatureId].geometry.coordinates)
    .setDOMContent(popupDiv)
    .addTo(map.value);
}

/**
 * Generates a filter expression for active markers based on the provided filter IDs.
 * @param {Array} newFilterIds - An array of filter IDs to generate the expression from.
 * @returns {Array} - The generated filter expression.
 */
const generateActiveMarkerFilterExpression = (newFilterIds) => {
  const baseExpression = ['case'];
  
  for (const id of newFilterIds) {
    baseExpression.push(['in', id, ['get', 'categories']], 1);  // Add outcome (1) for each condition
  }
  
  baseExpression.push(0.3);  // Default outcome
  
  return baseExpression;
}

/****************************/
/*LAYERS AND SOURCES*/ 
/****************************/
const addCurrentLocationLayer = () => {
  //const {lng,lat} = mapStore.options;
  const lng = mapStore.options.device_location_lng;
  const lat = mapStore.options.device_location_lat;
  map.value.addSource('currentLocationSource', {
    'type': 'geojson',
    'data': {
        "type": "Feature",
        "properties": {},
        "geometry":  {
            "type": "Point",
            "coordinates": [lng,lat]
        }
    }
  });

  map.value.addLayer({
    id: 'currentLocation',
    type: 'symbol', 
    source: 'currentLocationSource',
    layout:{
      'icon-image': 'current-location-marker',
      'icon-size':    scaleZoomMatrix(mapStore.options.markerZoomRatio,  0.9 * mapStore.options.minMarkerSize , 0.7 * mapStore.options.maxMarkerSize),
      //'icon-size':    returnMarkerScale(0.3,1.3),
      'text-anchor': 'bottom',
      'icon-anchor': 'bottom',
      "icon-allow-overlap": true,  
      "icon-ignore-placement": true
    }
  });
}

const setupClusterSource = () => {
  console.log("added source clustering");
    map.value.addSource('clustering', {
        'type': 'geojson',
        'data': clusters.value,
        'cluster': true,
        clusterMaxZoom: mapStore.options.clusterMaxZoom,
        clusterRadius: 80,
        clusterProperties: {
            'hasOpaqueMarker': ['any', ['get', 'hasOpaqueMarker']]
        }
    });
};

function handleSourceData(e) {
    if (e.sourceId === 'clustering' && map.value.isSourceLoaded('clustering')) {
        console.log('clustering source loaded');
        mapReady.value = true;
        // Remove the event listener
        map.value.off('sourcedata', handleSourceData);
    }
}

const scaleBySize = (min, max, useDevicePixelRatio) => {

  const bottom = 667;
  const top =  3600;
  let res = min;
  if(windowMaxDim > top)  res = max ;
  else if (windowMaxDim >= bottom) res = min + (windowMaxDim - bottom)*(max-min)/(top-bottom);
  
  if(useDevicePixelRatio === true) res = res /window.devicePixelRatio
  return res;
};

const addClusterLayer = (minSize, maxSize, suffix) => {

  /*
  let textSize = 58;
  if(windowMaxDim <= 1920) {
    textSize = 29;
  }
  */
 //const textSize = scaleBySize(36.25 * minSize, 52.72 * maxSize); // 
 //const textSize = 50;
 //const iconSize = scaleBySize(minSize, maxSize);
 
 let iconSize = scaleZoomMatrix(mapStore.options.markerZoomRatio,  minSize ,maxSize);
 //let textSize = scaleZoomMatrix(mapStore.options.markerZoomRatio,  70 * minSize  ,70 * maxSize);
 const textProps = calculateClusterTextProps(minSize, maxSize);

 //console.log('minSize:'+minSize + '   maxSize:'+maxSize);
 console.log('CLUSTER windowMaxDim:' + windowMaxDim + '    textSize:'+textProps.top +  '   iconSize:'+iconSize+'       minSize:'+minSize + '   maxSize:'+maxSize);
 map.value.addLayer({
        id: 'clusters'+suffix,
        type: 'symbol',
        source: 'clustering',
        filter: ['has', 'point_count'], // Filter for cluster features
        layout: {
          'icon-image': 'cluster-marker', // Use the custom cluster marker image
          //'icon-size': returnClusterSize(),
          'icon-size': iconSize,
          "icon-allow-overlap": true,  // Allow icons to overlap with each other
          "icon-ignore-placement": true,  // Ignore the placement of other icons when placing a particular icon
          'icon-anchor': 'bottom-left',
          'text-anchor': 'center',
          // Add the following properties
          'text-field': '+{point_count_abbreviated}',
          'text-size': textProps.size,
          //'text-offset': returnClusterTextOffset(2.5,-0.75),  // Adjust if needed, to position the text relative to the icon
          //'text-radial-offset': 0.5,
          "icon-allow-overlap": true, 
          'text-offset': [1.53, -2.57],

        },
        paint: {
          'text-color': '#ffffff',
          'icon-opacity': [
            'case',
            ['boolean', ['get', 'hasOpaqueMarker'], false],
            1, // Full opacity if there's at least one opaque marker
            1 // Reduced opacity otherwise
        ]
        }
      });
}





function calculateClusterTextProps(minMarker,maxMarker, zoom) {  

  if(zoom == undefined) zoom = map.value.getZoom();
  const bottom = 0;
  const top =  22;

  const pyHeight = 216
  const textSize = 0.24;
  //const textoffsetTop = -0.4;

  let res = {};

  let iconHeight = scaleBySize(minMarker, maxMarker) * pyHeight;
  
  let factor = mapStore.options.markerZoomRatio;
  //res = min + (windowMaxDim - bottom)*(max-min)/(top-bottom);
  if(zoom < 22) factor = 1 + ((zoom - bottom) * (mapStore.options.markerZoomRatio - 1) /(top-bottom));
  iconHeight = iconHeight * factor;

  res.size =  textSize * iconHeight;

  //res.top =  textoffsetTop * iconHeight ; 
  //res.left = 0;
  
  return res;
}




const addImageMarkerLayer = (expressionMarkerPart, minSize, maxSize, descriptor) => {
  
  if (expressionMarkerPart.length > 1) {
    expressionMarkerPart.push('default-marker');
  } else {
    expressionMarkerPart = 'default-marker'
  }

  let layout = {}; 

  let iconSize = scaleZoomMatrix(mapStore.options.markerZoomRatio,  minSize ,maxSize, true);
  console.log('MARKER windowMaxDim:' + windowMaxDim + '    iconSize:'+ iconSize +'       minSize:'+minSize + '   maxSize:'+maxSize);
  layout = {
        'visibility' : 'none',
        'icon-size': iconSize,
        'text-offset': [0, 1],
        'text-anchor': 'bottom',
        'icon-anchor': 'bottom',
        "icon-allow-overlap": true,  // Allow icons to overlap with each other
        "icon-ignore-placement": true  // Ignore the placement of other icons when placing a particular icon
    }
    if(expressionMarkerPart != 'default-marker')  layout['icon-image'] = [
          'case',
          ...expressionMarkerPart
        ];

      //console.log('kurac adding layer' +'image-markers'+descriptor )
      map.value.addLayer({
        id: 'image-markers'+descriptor,
        type: 'symbol',
        source: 'clustering',
        filter: ['!', ['has', 'point_count']], // Filter for individual markers (not clusters)
        layout: layout,
        paint: {
          'icon-opacity': ['get', 'opacity']
        }
      });
      map.value.moveLayer('image-markers'+descriptor, 'closeMarkerLayer');
      imageMarkerClickMapListener();
      imageMarkerLayerReady = true;
};

const addMarkerLayer = (expressionMarkerPart, minSize, maxSize, descriptor) => {
  
  if (expressionMarkerPart.length > 1) {
    expressionMarkerPart.push('default-marker');
  } else {
    expressionMarkerPart = 'default-marker'
  }

  let layout = {}; 

  let iconSize = scaleZoomMatrix(mapStore.options.markerZoomRatio,  minSize ,maxSize, true);
  console.log('MARKER windowMaxDim:' + windowMaxDim + '    iconSize:'+ iconSize +'       minSize:'+minSize + '   maxSize:'+maxSize);
  layout = {
        'icon-size': iconSize,
        'text-offset': [0, 1],
        'text-anchor': 'bottom',
        'icon-anchor': 'bottom',
        "icon-allow-overlap": true,  // Allow icons to overlap with each other
        "icon-ignore-placement": true  // Ignore the placement of other icons when placing a particular icon
    }
  
    //useSetMap().allMapData = layout;
    if(expressionMarkerPart != 'default-marker')  layout['icon-image'] = [
          'case',
          ...expressionMarkerPart
        ];


      map.value.addLayer({
        id: 'markers'+descriptor,
        type: 'symbol',
        source: 'clustering',
        filter: ['!', ['has', 'point_count']], // Filter for individual markers (not clusters)
        layout:  layout,
        paint: {
          'icon-opacity': ['get', 'opacity']
        }
      });
};

const addFavoritesLayer = () => {  
  map.value.addSource('favoritesSource', {
        'type': 'geojson',
        'data': {
            "type": "Feature",
            "properties": {},
            "geometry":  {
                "type": "Point",
                "coordinates": []
            }
        }
    });

  map.value.addLayer({
        id: 'favorites',
        type: 'symbol',
        source: 'favoritesSource',
        layout:{
          'icon-image': [
            'match',
          ['get', 'category'],
          'favorites-80', 'favorites-80-image',
          'favorites-81', 'favorites-81-image',
          'favorites-82', 'favorites-82-image',
          'favorites-83', 'favorites-83-image',
          'favorites-favorited-event','favorites-favorited-event-image',
          'cluster-marker' // Default case
        ],
        'icon-offset': [0,-2 * window.devicePixelRatio], // Example offset: 0px right, 15px up
        //'icon-size': returnMarkerScale(0.5,1.5),
        'icon-size': scaleZoomMatrix(mapStore.options.markerZoomRatio,  0.93 * mapStore.options.minMarkerSize , 0.93 * mapStore.options.maxMarkerSize, true),
        'text-anchor': 'bottom',
        'icon-anchor': 'bottom',
        "icon-allow-overlap": true,  // Allow icons to overlap with each other
        "icon-ignore-placement": true  // Ignore the placement of other icons when placing a particular icon
      }
    });
};

const addCloseMarkerLayer = () => {
map.value.addSource('closeSourceMarker', {
      'type': 'geojson',
      'data': {
          "type": "Feature",
          "properties": {},
          "geometry":  {
              "type": "Point",
              "coordinates": []
          }
      }
  });

  map.value.addLayer({
      id: 'closeMarkerLayer',
      type: 'symbol',
      source: 'closeSourceMarker',
      layout:{
        'icon-image': [
          'match',
        ['get', 'category'],
        'close-80', 'close-80-image',
        'close-81', 'close-81-image',
        'close-82', 'close-82-image',
        'close-83', 'close-83-image',
        'close-event', 'close-event-image',
        'cluster-marker' // Default case
      ],
      'icon-offset': [0,-35 * window.devicePixelRatio], // prej blo -35
      'icon-size': scaleZoomMatrix(mapStore.options.markerZoomRatio,  0.83 * mapStore.options.minMarkerSize , 0.83 * mapStore.options.maxMarkerSize, true),
      //'icon-size': returnMarkerScale(0.45,1.25),
      'text-anchor': 'bottom',
      'icon-anchor': 'bottom',
      "icon-allow-overlap": true,  // Allow icons to overlap with each other
      "icon-ignore-placement": true  // Ignore the placement of other icons when placing a particular icon
    }
  });
  closeMarkerLayerReady = true;
  //closermarkerClickMapListener();
};

function addCloseEventMarkerLayer() {
  map.value.addSource('closeEventSourceMarker', {
    'type': 'geojson',
    'data': {
        "type": "Feature",
        "properties": {},
        "geometry":  {
            "type": "Point",
            "coordinates": []
        }
    }
  });

  map.value.addLayer({
    id: 'closeEventMarkerLayer',
    type: 'symbol',
    source: 'closeEventSourceMarker',
    layout:{
        'icon-image': [
          'match',
        ['get', 'category'],
        'close-with-event-80','close-with-event-80-image',
        'close-with-event-81','close-with-event-81-image',
        'close-with-event-82','close-with-event-82-image',
        'close-with-event-83','close-with-event-83-image',
        'close-marker-with-opened-event','close-with-event-marker-with-opened-event-image',
        'cluster-marker' // Default case
      ],
      'icon-offset': [2,0 * window.devicePixelRatio], 
      'icon-size': scaleZoomMatrix(3.83,  0.83 * 0.175 , 0.83 * 0.5, true),
      //'icon-size': returnMarkerScale(0.45,1.25),
      'text-anchor': 'bottom',
      'icon-anchor': 'bottom',
      "icon-allow-overlap": true,  // Allow icons to overlap with each other
      "icon-ignore-placement": true  // Ignore the placement of other icons when placing a particular icon
    }
  });
  closeEventMarkerLayerReady = true;
}

/*****************/
/*NOT USED YET*/
/*****************/
const mapRadiusFilter = () => {
  let mapCenter = [mapStore.options['lng'], mapStore.options['lat']];
  let journeyTime = '60';

  let circles = [returnMapRadius(mapCenter, '5')];

  var radiusCircle = [];
  let name = '60' + "-" + '10' + "-radius";
  circles[0].geometry.coordinates[0].forEach(function (e) {
    radiusCircle.push(e);
  });

  var dataMap5gm = {
    'type': 'geojson',
    'data': {
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': [
          radiusCircle
        ]
      }
    }
  }

  try {
    //debugger;
    map.value.addSource(name, dataMap5gm);
    let id = "outline-" + 'walk' + "-" + journeyTime + "-km";
    console.log("id", id);
    map.value.addLayer({
      'id': id,
      'type': 'line',
      'source': name,
      'layout': {},
      'paint': {
        'line-color': '#73ff00',
        'line-width': 25,
        'line-dasharray': [4, 6]
      }
    });
  } catch (error) {
    console.log("error", error);
  }
}

function scaleZoomMatrix( zoomRatio, minSize, maxSize, useDevicePixelRatio) {

 let size = scaleBySize(minSize, maxSize, useDevicePixelRatio);

  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    0, size,
    22, size*zoomRatio
  ]
}


function returnMapRadius(mapCenter, radius) {
  var optionsCircle = { steps: 50, units: "kilometers", properties: { foo: "bar" } };
  let journeyTime = 60;
  let calculatedRadius = radius * journeyTime;

  let radiusCoordinates = turf.circle(mapCenter, calculatedRadius, optionsCircle);
  return radiusCoordinates;
}

/*****************/
/*WATCHERS*/
/*****************/

watch(() => useMapStoreData().activePoi , () => {
  if(useMapStoreData().activePoi  != null) {
    openMarker(useMapStoreData().activePoi );
  }else {
    closePoiPresentation();
  }
}, { deep: true });


watch(() => markersStore.markersWithImageCreated, () => {
  if(markersStore.markersWithImageCreated) {
    checkImageMarkersReady();
  }
}, { deep: true });

watch(() => mapReady.value, (newValue) => {
  if(newValue) {
    checkImageMarkersReady();
  }
});

watch(() => markersStore.functionalMarkersLoaded, () => {
  if(markersStore.functionalMarkersLoaded) {
    checkMarkersReady();
  }
}, { deep: true });


watch(() => mapStore.showMap, (newValue) => {
  if(mapStore.showMap) {
    useEventStore().eventNumberFilter = allEventLength.value;
  }
  if(!newValue) {
    closePoiPresentation();
  }
}, { deep: true });

watch(() => useMarkers.activeFilters, () => {
  if(mapLoaded){
    let fullLength = useCategoriesStore().poiCategories.size - 4;
    if(useMarkers.activeFilters.length == fullLength && 
    useSearchData().searchFilteredItems.length == 0){
      useSetMap().setAllMarkersData();
    }else {
      clearFilters();
    }
    
  }

}, { deep: true });

watch(() => route.fullPath, () => {
  //check if it doesnt have scenarios in the path
  if(pathRoute.value) {
    useEventStore().eventNumberFilter = allEventLength.value;
  }
});


watch(() => useMapStoreData().isOnline, (newValue) => {
  if(newValue) {
    initMap();
  }
})


</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/MapView.scss';

canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.empty-loading-bar {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.loading-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}
</style>
